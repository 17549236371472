import * as React from "react"
import { Router } from "@reach/router"
import HomePage from "./home.js"
import About from "./about.js"
import Newsroom from "./newsroom.js"
import Pricing from "./pricing.js"
import Contact from "./contact.js"
import PrivacyPolicy from "./privacyPolicy.js"

const IndexPage = () => {
  return (
    <>
      <Router basepath="">
        <HomePage path="/" />
        <About path="/about" />
        <Newsroom path="/newsroom" />
        <Contact path="/contact" />
        <PrivacyPolicy path="/privacyPolicy" />
      </Router>
    </>
  )
}

export default IndexPage
