import React, { useState, useEffect } from "react"
import Page from "../components/page.js"

import '../components/css/newsroom.scss'


const News = () => {
  const [feed, setFeed] = useState([]);

  function fetchDataFromRssFeed(feedLink) {
    var request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        var myObj = JSON.parse(request.responseText);
        for(var i = 0; i < myObj.items.length; i++) {
          setFeed(feed => [...feed, myObj.items[i]]);
        }
      }
    }
    request.open("GET", feedLink, true);
    request.send();
  }

  function fetchAllRssFeeds() {
    var feeds = [
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.usda.gov%2Frss%2Flatest-releases.xml",
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.usda.gov%2Frss%2Fhome.xml",
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.usda.gov%2Frss%2Flatest-blogs.xml"
    ];

    var feedData = [];

    for (var i = 0; i < feeds.length; i++) {
      fetchDataFromRssFeed(feeds[i]);
    }
  }

  function formatDate(dateString) {
    var day = dateString.split(" ");
    var date = new Date(day[0]);
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString("en-US", options);
  }

  useEffect(() => {
    fetchAllRssFeeds()
  }, []);

  useEffect(() => {
    feed.sort(function(first, second) {
      var day1 = first.pubDate.split(" ");
      var date1 = new Date(day1[0]);
      var day2 = second.pubDate.split(" ");
      var date2 = new Date(day2[0]);
     return date2 - date1;
    });
  }, [feed]);

  return (
    <>
      <Page>
        <div className="news-container">
          <div className="news-content-wrapper content-wrapper">
            <div className="news-content">
              <h3>News</h3>
              <div className="feed">
                {
                  feed.map(function(object, i){
                    return (
                      <>
                        <a className="article-link" href={object.link} target="#">
                          <div className="article">
                            <h4 className="date">{formatDate(object.pubDate)}</h4>
                            <div className="content">
                              <h4 className="title">{object.title}</h4>
                              <h5 className="description">{object.description.replace(/<\/?[^>]+(>|$)/g, "")}</h5>
                            </div>
                          </div>
                        </a>
                      </>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  )
}

export default News
