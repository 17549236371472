import * as React from "react"
import Page from "../components/page.js"
import GetStarted from "../components/getStarted.js"
import { StaticImage } from "gatsby-plugin-image"

import California from "../images/cali.svg";

import '../components/css/about.scss'

const About = () => {
  return (
    <>
      <Page backgroundColor='#34495e' headerLogoColor='#ffffff' headerColor='#ffffff'>
        <div className="about-container">
          <div className="mission-content-wrapper">
            <div className="mission-content">
              <h2>Our mission is to democratize artificial intelligent farming.</h2>
              <h3>InstaLeaf is a technology company that builds AI-driven crop management infrastructure for the agriculture industry. Farms of every size &#8212; from family to enterprise &#8212; use our software to manage their grows.</h3>
            </div>
          </div>
          <div className="location-content-wrapper">
            <div className="location-content">
              <div className="location-description">
                <h2>Located in the heart of agriculture</h2>
                <h3>
                  With headquarters in Silicon Valley and sister office in Los Angeles,
                  we have deep roots in U.S.A's biggest agriculture producer &#8212; California.
                  At InstaLeaf we are committed to delivering high value technology to our customers,
                  and help them better their cultivation process. We are an engineering driven team who
                  specialize in developing intelligent systems that can find meaning beyond the human eye.
                  Whether you are a new-comer or experienced grower, we hope to impress on you our
                  technology and how if can help improve your process.
                </h3>
              </div>
              <div className="location-svg">
                <California className="cali-svg"/>
              </div>
            </div>
          </div>
          <div className="team-content-wrapper">
            <h2>Leadership</h2>
            <div className="team-content">
              <div className="person">
                <div className="person-img">
                  <StaticImage
                    src="../images/gajan.jpg"
                    alt="Gajan Nagaraj"
                    className=""
                  />
                </div>
                <div className="person-description">
                  <h5>Gajan Nagaraj</h5>
                  <h6>CEO</h6>
                </div>
              </div>

              <div className="person">
                <div className="person-img">
                  <StaticImage
                    src="../images/hugh.jpg"
                    alt="Hugh Nugyen"
                    className=""
                  />
                </div>
                <div className="person-description">
                  <h5>Hugh Nguyen</h5>
                  <h6>COO</h6>
                </div>
              </div>

              <div className="person">
                <div className="person-img">
                  <StaticImage
                    src="../images/panda.jpg"
                    alt="Huy Nugyen"
                    className=""
                  />
                </div>
                <div className="person-description">
                  <h5>Huy Nguyen</h5>
                  <h6>General Partner</h6>
                </div>
              </div>

              <div className="person">
                <div className="person-img">
                  <StaticImage
                    src="../images/drn.jpg"
                    alt="Dr. N Nagaraj"
                    className=""
                  />
                </div>
                <div className="person-description">
                  <h5>Dr. N Nagaraj</h5>
                  <h6>Technical Advisor</h6>
                </div>
              </div>

              <div className="person">
                <div className="person-img">
                  <StaticImage
                    src="../images/ming.jpeg"
                    alt="Ming"
                    className=""
                  />
                </div>
                <div className="person-description">
                  <h5>Ming Liu</h5>
                  <h6>Grow Science Expert</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GetStarted color="#34495e"/>
      </Page>
    </>
  )
}

export default About
